:root {
  --width: 10vh;
  --size: calc(var(--width) / 4);
}

body {
  margin: 0;
  padding: 0;
  font-family: Mulish;
}
* {
  box-sizing: border-box;
  line-height: normal;
  font-family: inherit;
  margin: unset;
}
a {
  display: block;
}
ul {
  margin: 0;
  margin-inline: unset !important;
  padding: 0;
  list-style: none;
}
.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #faf7f7;
  border-radius: 10px;
}
input {
  background-color: transparent;
  width: 100%;
}
input:focus {
  outline: none;
}

.ps-menu-icon {
  width: unset !important;
  min-width: unset !important;
  justify-content: start !important;
  margin: 0 !important;
}
.ps-menu-button,
.ps-menu-icon {
  height: unset !important;
}
.ps-menu-button:hover {
  background-color: unset !important;
}
.ps-sidebar-container,
.ps-submenu-content {
  background-color: transparent !important;
}
.ps-collapsed .ps-menu-icon {
  min-width: 100% !important;
  justify-content: center !important;
}
.ps-collapsed {
  width: 80px !important;
}
.ps-sidebar-container,
.ps-menu-root,
.ps-menu-root > ul {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ps-sidebar-root {
  min-width: unset !important;
  border: none !important;
}
.ps-collapsed,
.ps-collapsed .ps-menu-root,
.ps-collapsed .ps-menu-button {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: unset !important;
  margin-right: unset !important;
}
.ps-collapsed ul {
  align-items: center;
}
.ps-menu-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ps-sidebar-container::-webkit-scrollbar {
  width: 2px;
}


.four-col{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap:10px
}


@media (max-width:350px) {
  .four-col{
    grid-template-columns: repeat(1,1fr);

  }
}


/* Loader style */
#loader-wrapper {
  width: calc(var(--width) + var(--size));
  height: calc(var(--width) + var(--size));
}
#dot-wrapper {
  width: var(--width);
  height: var(--width);
  position: relative;
  padding: calc(var(--size) / 2);
  animation: rotate 4s infinite linear;
}
.dot {
  width: 1px;
  height: 1px;
  position: absolute;
  top: calc(50% - 0.5px);
  left: calc(50% - 0.5px);
  border-radius: 50%;
  background: rgb(223, 225, 228);
  animation: scale 1s infinite linear;
}
#dot1 {
  transform: rotate(36deg) translate(0px, calc(var(--width) / -2));
  animation-delay: -0.9s;
  background: rgb(5, 24, 53);
}
#dot2 {
  transform: rotate(72deg) translate(0px, calc(var(--width) / -2));
  animation-delay: -0.8s;
}
#dot3 {
  transform: rotate(108deg) translate(0px, calc(var(--width) / -2));
  animation-delay: -0.7s;
}
#dot4 {
  transform: rotate(144deg) translate(0px, calc(var(--width) / -2));
  animation-delay: -0.6s;
}
#dot5 {
  transform: rotate(180deg) translate(0px, calc(var(--width) / -2));
  animation-delay: -0.5s;
}
#dot6 {
  transform: rotate(216deg) translate(0px, calc(var(--width) / -2));
  animation-delay: -0.4s;
}
#dot7 {
  transform: rotate(252deg) translate(0px, calc(var(--width) / -2));
  animation-delay: -0.3s;
}
#dot8 {
  transform: rotate(288deg) translate(0px, calc(var(--width) / -2));
  animation-delay: -0.2s;
}
#dot9 {
  transform: rotate(324deg) translate(0px, calc(var(--width) / -2));
  animation-delay: -0.1s;
}
#dot10 {
  transform: rotate(360deg) translate(0px, calc(var(--width) / -2));
  animation-delay: -1s;
}
@keyframes rotate {
  to {transform: rotate(360deg);}
}
@keyframes scale {
  from {
    width: var(--size);
    height: var(--size);
    top: calc(50% - calc(var(--size) / 2));
    left: calc(50% - calc(var(--size) / 2));
  }
  to {width: 1px; height: 1px;}
}